exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-build-tsx": () => import("./../../../src/pages/build.tsx" /* webpackChunkName: "component---src-pages-build-tsx" */),
  "component---src-pages-clear-storage-js": () => import("./../../../src/pages/clear-storage.js" /* webpackChunkName: "component---src-pages-clear-storage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-test-markdown-tsx": () => import("./../../../src/pages/test-markdown.tsx" /* webpackChunkName: "component---src-pages-test-markdown-tsx" */),
  "component---src-pages-test-websocket-js": () => import("./../../../src/pages/test-websocket.js" /* webpackChunkName: "component---src-pages-test-websocket-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

